<template>
<div>
  <v-card class="mx-auto mt-5" flat>
    <v-card-title style="word-break: normal;">
      Die Seite, die du suchst gibt es anscheinend nicht...
    </v-card-title>
    <v-card-text>
      Hier kommst du zur 
      <router-link to="/">Startseite</router-link>, und hier geht es zum 
      <router-link to="/login">Login</router-link>.
    </v-card-text>
  </v-card>
</div>
</template>

<script>

export default {

  data() {
    return {
      
    }
  },


  async created() {

  },

  methods: {

  }
}
</script>

<style>

</style>